import { Loading } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

let loading;

export function showLoading(target) {
  if (!loading) {
    loading = Loading.service({
      lock: true,
      text: "Loading...",
      background: "rgba(255, 255, 255, 0.5)",
      target: target || "body"
    });
  }
}

export function hideLoading() {
  if (loading != null) {
    loading.close();
  }
  loading = null;
}
